@import "config";
@import "animations";

.transactionsPage {
  .buttonsContainer {
    display: flex;
    [data-wait] {
      animation: rotate 2s linear infinite;
    }
    > div {
      + div {
        margin-left: $contentDefaultIndent;
      }
    }
    .syncInfo {
      display: flex;
      align-items: center;
      height: 100%;
      svg {
        font-size: 1.4em;
      }
    }
    .groupedByMonth {
      margin-right: $contentDefaultIndent;
      border-radius: $contentDefaultIndent;
      background-color: $uiWhiteColor;
      button {
        min-width: 5rem;
      }
    }
  }
  .cardHeader {
    padding-bottom: $contentDefaultIndent;
    > div:first-child {
      min-width: 0;
    }
    h6 {
      margin-bottom: $contentDefaultIndent;
    }
    .statusDescription {
      margin-top: $contentDefaultIndent;
      opacity: 0.75;
      line-height: 1;
    }
    .totalBalance {
      flex: none;
      margin-left: $contentDefaultIndent * 2;
      div {
        font-size: 1.1em;
        font-weight: 500;
        line-height: 1.2;
        text-align: right;
      }
    }
  }
  .cardBody {
    padding: 0 0 $contentDefaultIndent * 2 0;
  }
  .actionsButtons {
    > button {
      padding: 0.65em 0.85em;
    }
  }
}
