@import "config";

.commentsWindow {
  width: 580px;
  :global(.modal) {
    &-body {
      padding-right: 0;
      padding-left: 0;
    }
  }
  .transactionInfo {
    display: flex;
    padding-right: $contentDefaultIndent * 2;
    padding-left: $contentDefaultIndent * 2;
    .amount,
    .date,
    .description {
      white-space: nowrap;
    }
    .date,
    .description {
      margin-left: $contentDefaultIndent * 4;
    }
    .description {
      position: relative;
      flex: 1;
      .tooltip {
        position: absolute;
        top: 0;
        left: 0;
        width: fit-content;
        max-width: 100%;
        > div {
          max-width: 100%;
        }
      }
    }
  }
  .commentsBlock {
    height: 24rem;
    padding: 0 $contentDefaultIndent * 2;
  }
}
