@import "config";

.table {
  white-space: nowrap;
  .checkboxCell {
    flex: none;
    width: 34px;
    padding-right: 0;
    padding-left: $contentDefaultIndent * 2;
  }
  .dateCell {
    flex: 6;
    width: 6px;
    padding-left: $contentDefaultIndent * 2;
  }
  .accountCell {
    flex: 10;
    width: 10px;
  }
  .addressCell {
    flex: 10;
    width: 10px;
  }
  .extraCell {
    flex: 14;
    width: 14px;
  }
  .amountCell {
    flex: 8;
    justify-content: flex-end;
    width: 8px;
    padding-right: $contentDefaultIndent * 2;
  }
  .notificationCell {
    flex: 4;
    width: 4px;
    [data-id] {
      cursor: pointer;
    }
  }
}
